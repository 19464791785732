import {Component, HostBinding, Input} from '@angular/core';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {FaVariant} from '../fa/fa.component';

@Component({
    selector: 'app-action-icon',
    template: `
        <fa [i]="icon" [variant]="variant" [class.anti-pulsating]="pulsating"></fa>
        <span *ngIf="count || count === 0" class="bubble">{{count}}</span>
        <span *ngIf="addOnIcon" class="bubble"><fal [i]="addOnIcon"></fal></span>
    `,
})
export class ActionIconComponent extends NgbTooltip {
    @Input() icon: string;
    @Input('variant') defVariant: FaVariant = 'light';
    @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';
    @Input() colorClass: string;
    @Input('class') classList = '';
    @Input() isVisible = true;
    @Input() count: number | string;
    @Input() addOnIcon: string;

    @HostBinding('class') get classes() {
        return `action-icon action-icon-${this.colorClass} ${this.size} ${this.classList} ${this.isVisible ? '' : ' d-none'}`;
    }

    @HostBinding('class.active')
    @Input() active = false;

    @HostBinding('class.pulsating')
    @Input() pulsating = false;

    container = 'body';
    @Input('tooltip') set tooltip(tooltip) {
        this.ngbTooltip = tooltip;
    }

    get variant(): FaVariant {
        if (['ellipsis-h', 'ellipsis-v'].includes(this.icon)) return 'regular';
        return this.active ? 'solid' : this.defVariant;
    }
}
